import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import { productDetails } from "@/data";
import ContactTwo from "@/components/contact-two";
import ServiceDetails from "@/components/products/product-details";

const ServiceDetailsPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Products Details">
        <HeaderOne />
        <PageHeader title="Natural Draught Cooling Towers" name="Natural Draught Cooling Towers" />
        <ServiceDetails data={productDetails.naturalDraught} />
        {/* <ContactTwo /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
